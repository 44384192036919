/* REACT IMPORTS */
import React, { useEffect } from 'react';
/* LIBRARIES IMPORTS */
import { SessionProvider } from 'next-auth/react';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { SnackbarProvider } from 'notistack';
/* CUSTOM IMPORTS */
import { useApollo } from "../lib/Apollo/client";
import { loadMomentLangs } from "../config/moment";
import { snackbarMaxSnack } from "../config";
import '../config/i18n';
import '../styles/globals.scss';
import '../styles/print.scss';

// Remove console log when not in dev env
if (process.env.NODE_ENV !== "development")
    console.log = () => {};

const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#649AF0',
            // dark: will be calculated from palette.primary.main,
            contrastText: '#ffffff'
        },
        secondary: {
            //light: '#0066ff',
            main: '#8E77D1',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffffff',
        },
        grey: {
            main: grey[300],
            dark: grey[400]
        },
        basicBlue: {
            main: '#148aeb',
            contrastText: '#ffffff',
        }
    },
});

export default function App({ Component, pageProps }) {
    const getLayout = Component.getLayout || (page => page);
    const apolloClient = useApollo(pageProps.initialApolloState);

    useEffect(() => {
        loadMomentLangs();
    }, []);

    return getLayout(
        <ApolloProvider client={apolloClient}>
            <SessionProvider session={pageProps.session}>
                <CookiesProvider>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider maxSnack={snackbarMaxSnack}>
                            <Component {...pageProps}/>
                        </SnackbarProvider>
                    </ThemeProvider>
                </CookiesProvider>
            </SessionProvider>
        </ApolloProvider>
    );
};