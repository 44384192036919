import { useMemo } from 'react';
import { ApolloClient, ApolloLink, InMemoryCache, from, split } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from "@apollo/client/link/retry";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { getSession } from 'next-auth/react';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createUploadLink } from 'apollo-upload-client';
import Websocket from 'isomorphic-ws';
import { createClient } from 'graphql-ws';
import { sha256 } from 'crypto-hash';
import omitDeep from 'omit-deep-lodash';
//import WebSocket from 'ws';

let apolloClient;

export function initializeApolloClient(context = null, details = {}) {
    const _apolloClient = apolloClient ? apolloClient : createApolloClient(context, details);

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (details.initialState) {
        _apolloClient.cache.restore(details.initialState);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient
}

export function createApolloClient(context = null, details = {}) {
    // Are we in server side ?
    const ssrMode = details.isSSR !== 'undefined' ? details.isSSR : typeof window === 'undefined';

    /**
     * Create the http link with upload included
     *
     * @type {ApolloLink}
     */
    const httpLink = createUploadLink({
        uri: process.env.NEXT_PUBLIC_HOST + '/graphql',
        //credentials: "include",
        headers: {
            'Apollo-Require-Preflight': "true",
        },
    });

    // Get the token
    /*const withToken = setContext(async () =>{
        let session = null;
        if(details && details.session) {
            console.log("PASS 1");
            session = details.session;
        } else if(context && typeof window !== 'undefined') {
            console.log("PASS 2");
            session = await getSession(context);
        } else {
            console.log("PASS 3");
            session = await getSession();
        }

        console.log(session);

        const token = session && session.accessToken ? session.accessToken : '';
        return { token };
    });*/

    /**
     * Web socket link
     *
     * @type {GraphQLWsLink}
     */
    const wsLink = new GraphQLWsLink(createClient({
        url: process.env.NEXT_PUBLIC_WS_HOST + '/graphql',
        options: {
            //reconnect: true,
            //lazy: true,
            connectionParams: () => {
                const token = details.session && details.session.accessToken ? details.session.accessToken : '';
                return {
                    headers: {
                        "x-token": token,
                        'Apollo-Require-Preflight': "true",
                    }
                };
            },
        },
        webSocketImpl: Websocket,
    }));

    /**
     * Retry link
     *
     * Retry the queries an amount of time if there is a network issue
     *
     * @type {RetryLink}
     */
    const retryLink = new RetryLink({
        delay: {
            initial: 250,
            max: Infinity,
            jitter: true
        },
        attempts: {
            max: 5,
            retryIf: (error, _operation) => !!error
        }
    });

    /**
     * Persisted queries link
     * Make request lower in size
     *
     * @type {ApolloLink}
     */
    const persistedQueriesLink = createPersistedQueryLink({ sha256 });

    /**
     * Auth link
     * Get the session token and put it in the headers
     *
     * @type {ApolloLink}
     */
    const authMiddleware = setContext(async (_, { headers }) => {
        let session = null;
        if(details.session) {
            session = details.session;
        } else if(context && typeof window !== 'undefined') {
            session = await getSession(context);
        } else {
            session = await getSession();
        }

        const token = session && session.accessToken ? session.accessToken : '';
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                "x-token": token,
            }
        }
    });

    /**
     * Create error handler link
     * @type {ApolloLink}
     */
    const errorMiddleware = onError(({ response, graphQLErrors, networkError, operation, forward }) => {
        console.log("Response in error middleware :");
        console.log(response);
        console.log(operation);
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                console.log(err.extensions.code);
                switch(err.extensions.code) {
                    /**
                     * Disable the UNAUTHENTICATED case with new next.js
                     * - It makes infinite loop sometimes and no redirection
                     * - the function withAuthServerSide is in charge of the redirection now
                     */
                    /*case 'UNAUTHENTICATED':
                        if(context) {
                            context.res.writeHead(302, {
                                Location: '/logout',
                            });
                            /*context.res.end();*/
                        /*} else {
                            try {
                                Router.push('/logout');
                            } catch(err) {
                                console.log("Error middleware. Router push error.");
                                console.log(err);
                            }
                        }
                        break;*/
                    case 'INTERNAL_SERVER_ERROR':
                        console.log(err);
                        break;
                    default:
                        console.log("DEFAULT SWITCH ERROR :");
                        console.log(err);
                }
            }
        } else if(networkError) {
            console.log("NETWORK ERROR :");
            console.log(networkError);
            console.log(networkError.response);
        }
    });

    /**
     * Custom link
     *
     * Automatic remove of the typename param in mutations
     * @type {ApolloLink}
     */
    const cleanTypenameLink = new ApolloLink((operation, forward) => {
        const keysToOmit = ['__typename']; // more keys like timestamps could be included here

        const def = getMainDefinition(operation.query);
        if (def && def.operation === 'mutation') {
            operation.variables = omitDeep(operation.variables, keysToOmit)
        }
        return forward ? forward(operation) : null
    });

    /**
     * Create Http link flow
     * @type {ApolloLink}
     */
    const httpLinkFlow = from([
        cleanTypenameLink,
        errorMiddleware,
        retryLink,
        authMiddleware,
        persistedQueriesLink,
        httpLink,
    ]);

    /**
     * Create WebSocket link flow
     * @type {null}
     */
    /*let webSocketLinkFlow = null;
    if(isWS) {
        webSocketLinkFlow = from([
            //authMiddleware,
            errorMiddleware,
            wsLink,
        ]);
    }*/
    const webSocketLinkFlow = from([
        errorMiddleware,
        wsLink,
    ]);

    // Create Split link
    //let splitLink = httpLinkFlow;
    //if(isWS) {
        let splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            webSocketLinkFlow,
            httpLinkFlow,
        );
    //}

    return new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache({
            typePolicies: {
                Trip: {
                    fields: {
                        days: {
                            merge(existing = [], incoming = []) {
                                /*console.log("Merge days");
                                console.log('Existing : ');
                                console.log(existing);
                                console.log("Incoming : ");
                                console.log(incoming);*/
                                return incoming;
                            },
                        },
                    },
                },
                Day: {
                    fields: {
                        translations: {
                            merge(existing = [], incoming = []) {
                                /*console.log("Merge day translations");
                                console.log('Existing : ');
                                console.log(existing);
                                console.log("Incoming : ");
                                console.log(incoming);*/
                                return incoming;
                            },
                        },
                        dayBenefits: {
                            merge(existing = [], incoming = []) {
                                /*console.log("Merge dayBenefits translations");
                                console.log('Existing : ');
                                console.log(existing);
                                console.log("Incoming : ");
                                console.log(incoming);*/
                                return incoming;
                            },
                        }
                    },
                },
                DayBenefit: {
                    fields: {
                        serviceBookings: {
                            merge(existing = [], incoming = []) {
                                return incoming;
                            },
                        },
                        days: {
                            merge(existing = [], incoming = []) {
                                return incoming;
                            },
                        },
                        participants: {
                            merge(existing = [], incoming = []) {
                                return incoming;
                            },
                        }
                    },
                },
                ServiceBooking: {
                    fields: {
                        participants: {
                            merge(existing = [], incoming = []) {
                                return incoming;
                            },
                        },
                    },
                },
                Establishment: {
                   fields: {
                       address: {
                           merge(existing = [], incoming = []) {
                               return incoming;
                           }
                       }
                   }
                },
                Query: {
                    fields: {
                        tripsTemplate: {
                            merge(existing = [], incoming = []) {
                                return incoming;
                            },
                        },
                        tripDays: {
                            merge(existing = [], incoming = []) {
                                /*console.log("Merge tripDays");
                                console.log('Existing : ');
                                console.log(existing);
                                console.log("Incoming : ");
                                console.log(incoming);*/
                                return incoming;
                            },
                        },
                        agencyCustomers: {
                            merge(existing = [], incoming = []) {
                                return incoming;
                            },
                        },
                        termPresets: {
                            merge(existing = [], incoming = []) {
                                /*console.log("Merge tripDays");
                                console.log('Existing : ');
                                console.log(existing);
                                console.log("Incoming : ");
                                console.log(incoming);*/
                                return incoming ;
                            },
                        },
                        serviceBookings: {
                            merge(existing = [], incoming = []) {
                                /*console.log("Merge serviceBookings query");
                                console.log('Existing : ');
                                console.log(existing);
                                console.log("Incoming : ");
                                console.log(incoming);*/
                                return incoming ;
                            },
                        },
                        getTripTerms: {
                            merge(existing = [], incoming = []) {
                                /*console.log("Merge getTripTerms query");
                                console.log('Existing : ');
                                console.log(existing);
                                console.log("Incoming : ");
                                console.log(incoming);*/
                                return incoming ;
                            },
                        },
                        optionsAndDefaults: {
                            merge(existing = [], incoming = []) {
                                /*console.log("Merge optionsAndDefaults query");
                                console.log('Existing : ');
                                console.log(existing);
                                console.log("Incoming : ");
                                console.log(incoming);*/
                                return incoming ;
                            },
                        },
                    }
                }
            },
        }),
        ssrMode: ssrMode,
        defaultOptions: {
            mutate: {
                errorPolicy: 'none'
            },
            watchQuery: {
                errorPolicy: 'all',
                fetchPolicy: 'cache-and-network',
            },
            query: {
                errorPolicy: 'all'
            },
        },
        connectToDevTools: process.env.CUSTOM_ENV === 'development'
    });
}

export function useApollo(initialState) {
    return useMemo(() => initializeApolloClient(null, {initialState}), [initialState]);
}

